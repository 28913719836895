import { SyncAuthorization } from './sync-authorization';
import { HttpErrorResponse } from '@angular/common/http';
import { GoogleAuthService } from './social/google-auth.service';
import { AppleAuthService } from './social/apple-auth.service';
import { FacebookAuthService } from './social/facebook-auth.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { Environment } from '../../common/environment/environment';
import {
    ApiRequest,
    SosApiService,
} from '../../common/services/api/api.service';
import { SosCryptoService } from '../../helpers';
import { AzureAuthService } from './social/azure-auth.service';
import {
    AuthorizationData,
    ClaimAccountData,
    LoginData,
    LoginWithTokenData,
    RegisterData,
    RequestClaimAccountData,
    RequestResetPasswordData,
    ResetPasswordData,
    SessionResponseData,
    SocialProvider,
} from './authentication-types';
import { OauthService } from './social/oauth.service';

@Injectable({
    providedIn: 'root',
})
export class SosAuthorizationService {
    constructor(
        private cookieService: CookieService,
        private sosApiService: SosApiService,
        private sosCryptoService: SosCryptoService,
        private facebookAuthService: FacebookAuthService,
        private appleAuthService: AppleAuthService,
        private azureAuthService: AzureAuthService,
        private oauthService: OauthService,
        private googleAuthService: GoogleAuthService,
        private env: Environment
    ) {}

    checkAuthorization() {
        const cookie =
            this.cookieService.get('user-login') ||
            this.cookieService.get('_user-login');

        if (!cookie) {
            return null;
        }

        const sid = this.sosCryptoService.decryptData(
            cookie,
            this.env.cookieKey
        );

        if (!sid) {
            return null;
        }
        return sid;
    }

    mergeAccount(token) {
        return this.sosApiService.get('client/register_token_infos/id:' + token, {
            'paging[limit]': 9999,
            'expand[0]': 'all.all.all',
        });
    }

    private setAuthorization(sid, expire_time) {
        const date = new Date();
        date.setTime(expire_time * 1000);
        const csid = this.sosCryptoService.encryptData(sid, this.env.cookieKey);

        // TODO add correct set cookie for live
        if (this.env.production) {
            this.cookieService.set(
                'user-login',
                csid,
                date,
                '/',
                window.location.hostname,
                true,
                'Strict'
            );

            // this.cookieService.set(
            //     '_user-login',
            //     csid,
            //     date,
            //     '/',
            //     window.location.hostname,
            //     true,
            //     'Lax'
            // );
        } else {
            this.cookieService.set(
                'user-login',
                csid,
                date,
                '/',
                window.location.hostname,
                false,
                'Lax'
            );
        }
    }

    public logout() {
        return this.sosApiService
            .get(window.location.origin + '/ext/logout')
            .pipe(
                tap((response) => {
                    // TODO add correct delete cookie for live

                    if (this.env.production) {
                        this.cookieService.delete(
                            'user-login',
                            '/',
                            window.location.hostname,
                            true,
                            'None'
                        );

                        this.cookieService.delete(
                            '_user-login',
                            '/',
                            window.location.hostname,
                            true,
                            'Lax'
                        );

                        this.cookieService.delete(
                            'wa_social_data',
                            '/',
                            window.location.hostname,
                            true,
                            'None'
                        );

                        this.cookieService.delete(
                            '_wa_social_data',
                            '/',
                            window.location.hostname,
                            true,
                            'Lax'
                        );
                    } else {
                        this.cookieService.delete(
                            'user-login',
                            '/',
                            window.location.hostname,
                            false,
                            'Lax'
                        );
                    }
                })
            );
    }

    public login(
        data: LoginWithTokenData | LoginData,
        keepSingIn: boolean = false
    ): Observable<AuthorizationData> {
        const sessionSync = new SyncAuthorization();

        return this.sosApiService
            .post<SessionResponseData>('client/login', data, {
                keep_me_signed_in: keepSingIn,
            })
            .pipe(
                map(
                    (res) => {
                        sessionSync.sync(
                            this.sosCryptoService.encryptData(
                                res.data?.session,
                                this.env.cookieKey
                            )
                        );
                        this.setAuthorization(
                            res.data.session.sid,
                            res.data.session.expire_time
                        );

                        return res.data?.session;
                    },
                    (error) => {
                        sessionSync.cancelSync();
                    }
                )
            );
    }

    public register(
        data: RegisterData | ClaimAccountData
    ): Observable<AuthorizationData> {
        const sessionSync = new SyncAuthorization();
        return this.sosApiService
            .post<SessionResponseData>('client/register', data)
            .pipe(
                map(
                    (res) => {
                        sessionSync.sync(
                            this.sosCryptoService.encryptData(
                                res.data.session,
                                this.env.cookieKey
                            )
                        );
                        this.setAuthorization(
                            res.data.session.sid,
                            res.data.session.expire_time
                        );

                        return res.data.session;
                    },
                    (error) => {
                        sessionSync.cancelSync();
                    }
                )
            );
    }

    public requestClaimAccount(data: RequestClaimAccountData) {
        return this.sosApiService.post('client/claim_account', data);
    }

    public requestResetPassword(data: RequestResetPasswordData) {
        return this.sosApiService.post('client/request_reset_password', data);
    }

    public resetPassword(data: ResetPasswordData) {
        return this.sosApiService.post('client/reset_password', data);
    }

    public getSocialProviders() {
        return this.sosApiService
            .get<SocialProvider[] | null>('client/social_providers', {})
            .pipe();
    }

    public loginApple(socialProvider: SocialProvider) {
        return new Observable<AuthorizationData>((subscribe) => {
            this.appleAuthService
                .beginAuth(socialProvider)
                .then((response: any) => {
                    const sendData = {
                        social:  response.data
                    }

                    const loginData = sendData;

                    const sessionSync = new SyncAuthorization(
                        this.appleAuthService.popupInstance
                    );

                    this.sosApiService
                        .post<SessionResponseData>('client/login', loginData, {
                            keep_me_signed_in: true,
                        })
                        .subscribe(
                            (response) => {
                                sessionSync.sync(
                                    this.sosCryptoService.encryptData(
                                        response.data.session,
                                        this.env.cookieKey
                                    )
                                );

                                this.setAuthorization(
                                    response.data.session.sid,
                                    response.data.session.expire_time
                                );

                                subscribe.next(response.data.session);
                                subscribe.complete();
                            },
                            (err) => {
                                sessionSync.cancelSync();
                                subscribe.error(err);
                            }
                        );
                })
                .catch((error) => {
                    subscribe.error(error);
                });
        });
    }

    public loginGoogle(socialProvider: SocialProvider) {
        return new Observable<AuthorizationData>((subscribe) => {
            this.googleAuthService
                .beginAuth(socialProvider)
                .then((response: any) => {
                    this.setAuthorization(
                        response.data.session.sid,
                        response.data.session.expire_time
                    );

                    subscribe.next(response.data.session);

                    subscribe.complete();
                })
                .catch((err) => {
                    subscribe.error(err);
                });
        });
    }

    public loginFacebook(socialProvider: SocialProvider) {
        return new Observable<AuthorizationData>((subscribe) => {
            this.facebookAuthService
                .beginAuth(socialProvider)
                .then((response) => {
                    this.setAuthorization(
                        response.data.session.sid,
                        response.data.session.expire_time
                    );

                    subscribe.next(response.data.session);
                    subscribe.complete();
                })
                .catch((err) => {
                    subscribe.error(err);
                });
        });
    }

    public loginAzure(socialProvider: SocialProvider) {
        return new Observable<AuthorizationData>((subscribe) => {
            this.azureAuthService
                .beginAuth(socialProvider)
                .then((response) => {
                    this.setAuthorization(
                        response.data.session.sid,
                        response.data.session.expire_time
                    );
                    subscribe.next(response.data.session);
                    subscribe.complete();
                })
                .catch((err) => {
                    subscribe.error(err);
                });
        });
    }

    public loginOAuth2(socialProvider: SocialProvider) {

        return new Observable<AuthorizationData>((subscribe) => {
            this.oauthService
                .beginAuth(socialProvider)
                .then((response) => {

                    const loginData = {
                        social: response.data
                    };

                this.sosApiService
                    .post<SessionResponseData>('client/login', loginData, {
                        keep_me_signed_in: true,
                    })
                    .subscribe(
                        (response) => {
                            this.setAuthorization(
                                response.data.session.sid,
                                response.data.session.expire_time
                            );

                            subscribe.next(response.data.session);
                            subscribe.complete();
                        },
                        (err) => {
                            subscribe.error(err);
                        }
                    );
                })
                .catch((error) => {
                    subscribe.error(error);
                });
        });
    }
}
