import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { retry, catchError, map, tap, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SosCoreConfig } from '../../../core-config';
import { LocalStorageService } from '../local-storage/local-storage.service';

export class ApiRequest<T = any> {
    public _request: {
        type: 'GET' | 'POST' | 'DELETE' | 'PUT';
        url?: string;
        data?: any;
        options?: any;
    } | null = null;

    public data!: T;
    public error: { code: number; message: string }[] | null = null;
    public warning: any[] | null = null;
    public paging: any;

    public stack?: string = undefined;

    constructor(request: {
        type: 'GET' | 'POST' | 'DELETE' | 'PUT';
        url: string;
        data?: any;
        options?: any;
    }) {
        this._request = request;
    }

    public setResponse(response?) {
        if (response?.error) {
            // this.stack = new Error()?.stack?.toString();
        }

        this.data = response?.data || null;
        this.error = response?.error || null;
        this.warning = response?.warning || null;
        this.paging = response?.paging || null;
    }
}

@Injectable({
    providedIn: 'root',
})
export class SosApiService {
    public authToken: string | null = null;

    constructor(
        private httpClient: HttpClient,
        private config: SosCoreConfig,
        private translateService: TranslateService,
        private _localStorageService: LocalStorageService
    ) {}

    public get<T = any>(
        path: string,
        params: { [key: string]: any } = {}
    ): Observable<ApiRequest<T>> {
        const headers = this.createHeader();

        if (!path.includes('http')) {
            path = this.config.api_url + path;
        }

        let request = new ApiRequest({
            type: 'GET',
            url: path,
            options: { headers, params },
        });

        return this.httpClient.get(path, { headers, params }).pipe(
            tap(
                (res) => {
                    // console.log(`GET: \n${this.config.api_url + path} \nRESPONSE: \n`, res)
                },
                (err) => {
                    // console.log(`GET ERROR: \n${this.config.api_url + path}  \nRESPONSE: \n`, err)
                }
            ),
            retry(3), // retry a failed request up to 3 times
            catchError((error) => this.handleHttpErrors(error, request)), // then handle the error
            map((response) => {
                request.setResponse(response);

                if (request.error) {
                    throw request;
                }

                return request;
            })
        );
    }

    public post<T = any>(
        path: string,
        data: any,
        params: { [key: string]: any } = {}
    ): Observable<ApiRequest<T>> {
        const headers = this.createHeader();

        if (!path.includes('http')) {
            path = this.config.api_url + path;
        }

        let request = new ApiRequest({
            type: 'POST',
            data: data,
            url: path,
            options: { headers, params },
        });

        return this.httpClient.post(path, data, { headers, params }).pipe(
            tap(
                (res) => {
                    // console.log(`POST: \n ${this.config.api_url + path}  \n RESPONSE: \n`, res)
                },
                (err) => {
                    // console.log(`POST ERROR: \n ${this.config.api_url + path}  \n RESPONSE: \n`, err)
                }
            ),
            retry(3), // retry a failed request up to 3 times
            catchError((error) => this.handleHttpErrors(error, request)), // then handle the error
            map((response) => {
                request.setResponse(response);

                if (request.error) {
                    throw request;
                }

                return request;
            })
        );
    }

    public delete(
        path: string,
        data: any,
        params: { [key: string]: any } = {}
    ): Observable<ApiRequest> {
        const headers = this.createHeader();

        if (!path.includes('http')) {
            path = this.config.api_url + path;
        }

        let request = new ApiRequest({
            type: 'DELETE',
            data: data,
            url: path,
            options: { headers, params },
        });

        return this.httpClient
            .delete(path, { headers, params, body: data })
            .pipe(
                tap(
                    (res) => {
                        // console.log(`POST: \n ${this.config.api_url + path}  \n RESPONSE: \n`, res)
                    },
                    (err) => {
                        // console.log(`POST ERROR: \n ${this.config.api_url + path}  \n RESPONSE: \n`, err)
                    }
                ),
                retry(3), // retry a failed request up to 3 times
                catchError((error) => this.handleHttpErrors(error, request)), // then handle the error
                map((response) => {
                    request.setResponse(response);

                    if (request.error) {
                        throw request;
                    }

                    return request;
                })
            );
    }

    private handleHttpErrors(
        error: HttpErrorResponse,
        request: ApiRequest
    ): Observable<any> {
        let response = {
            error: [
                {
                    code: 100777,
                    message:
                        'Oops! Seems like something went wrong on our end! Please accept our apologies and try again.',
                },
            ],
        };

        // There is error.status == 0.Then request is blocked or something else has happened this is why we use then 100777
        if (error.status) {
            response.error[0].code = 100000 + error.status;
        }

        request.setResponse(response);

        // return an observable with a user-facing error message
        return throwError(request);
    }

    private createHeader() {

        const payloadGatewayParam = this.getParameterByName('fs_payload[gateway]', this.config?.payload);

        let  currentLanguage = '';

        if (this._localStorageService.getItem('app_default_language') && this._localStorageService.getItem('app_default_language') !== 'undefined') {
            currentLanguage = this._localStorageService.getItem('app_default_language');
        }
        
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('X-Application', this.config.key);
        headers = headers.append('X-Profile', this.config.profile_id);

        if (currentLanguage) {
            headers = headers.append('X-Language', currentLanguage);
        }

        if (payloadGatewayParam) {
            headers = headers.append('X-Gateway', payloadGatewayParam);
        }

        if (this.authToken) {
            headers = headers.append(
                'Authorization',
                `Bearer ${this.authToken}`
            );
        }

        return headers;
    }

    private getParameterByName(name, params) {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(params);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
